import React, { useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { authActions } from "../store";
import "../componnent-css/login.css"

function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const sendRequest = async () => {
    const res = await axios
      .post("http://localhost:4000/api/login", {
        email: inputs.email,
        password: inputs.password,
      })
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // send http request
    sendRequest()
      .then(() => dispatch(authActions.login()))
      .then(() => history("/user"));
  };
  return (   
    <div>
      <form onSubmit={handleSubmit}>
        <div
          className="container-login"
        >
          <h2 variant="h2">Login</h2>
          <div className = "input-box-login" >
          <input
          name="email"
          value={inputs.email}
          onChange={handleChange}
          variant="outlined"
          type="email"
          margin="normal"
          required="required"
          />
          <span>Email</span>
          <i></i>
          </div>
          <div className = "input-box-login" >
          <input
          name="password"
          onChange={handleChange}
          value={inputs.password}
            variant="outlined"
            type="password"
            margin="normal"
            required="required"
          />
          <span>Password</span>
          <i></i>
          </div>
          {/* {error && <div className=''>{error}</div>} */}
          <button variant="contained" type="submit" >Login</button>
        </div>
      </form>
    </div>
  )
}

export default Login
import React from 'react';
import './App.css';
import Header from './components/Header';
import {Routes, Route} from "react-router-dom"
import Login from './components/Login';
import Signup from './components/Signup';
import Welcome from './components/Welcome';
import {useSelector} from "react-redux"
import Stock from './components/Stock';
import Footer from './components/Footer';
import Projects from './components/Projects';
import Faq from './components/Faq';
import About from './components/About';

function App() {
  const isLoggedIn = useSelector((state) => state.isLoggedIn)
  console.log(isLoggedIn);
  return (
    <React.Fragment>
    <header>
      <Header/>
    </header>
    <main>
      <Routes>
      <Route path="/swedhan-stock" element={<Stock/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/faq" element={<Faq/>}/>
      {/* <Route path="/about-us" element={<About/>}/> */}
      <Route path="/login" element={<Login/>}/>
      <Route path="/signup" element={<Signup/>}/>
      { isLoggedIn && <Route path="/user" element={<Welcome/>}/>}{" "}
      <Route path='/' element={<Welcome/>}></Route>
      </Routes>
    </main>
    <div>
    <Footer/>
    </div>
    </React.Fragment>
  );
}

export default App;

import React from 'react'
import Soon from "../Images/So-soon.jpg"
import Mailer from './Mailer'

function Stock() {
  return (
    <div>
    <section class="home-welcome home-welcome">
    <img src={Soon} alt=""/>
    <div class="content-welcome active">
      <h1 className="h1-welcome" data-text="Membershipment">
      Membershipment
      </h1>
    </div>
    <div class="content-welcome active">
      <h1 className="h1-welcome" data-text="&">
      &
      </h1>
    </div>
    <div class="content-welcome active">
      <h1 className="h1-welcome" data-text="Stock">
      Stock
      </h1>
    </div>
  </section>
        <div>
        <h1 id="stock-stock-welcome">For More Info You Can</h1>
        <Mailer/>
      </div>
    </div>

  )
}

export default Stock
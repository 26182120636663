import React from "react";

function Footer() {
  return (
    <div>
      {" "}
      <div className="footer">
        <footer>
          <div className="waves_footer">
            <div className="wave_footer" id="wave1"></div>
            <div className="wave_footer" id="wave2"></div>
            <div className="wave_footer" id="wave3"></div>
            <div className="wave_footer" id="wave4"></div>
          </div>
          <ul className="social_icon_header">
            <li>
              <a herf="https://instagram.com/swedhan734?igshid=YmMyMTA2M2Y=">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>
            <li>
              <a herf="https://twitter.com/swedhan734?t=ebHuCH9KOI_SMCOUGrHq0A&s=09">
                <ion-icon name="logo-twitter"></ion-icon>
              </a>
            </li>
            <li>
              <a herf="https://discord.gg/4dPNDUqx">
                <ion-icon name="logo-discord"></ion-icon>
              </a>
            </li>
            <li>
              <a herf="https://www.linkedin.com/in/swed-han-90a1501ab">
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>
            </li>
          </ul>
          {/* <ul className="menu_footer">
        <li>
        <a herf="">Home</a>
        </li>
        <li>
          <a herf="#">vcdsome</a>
        </li>
        <li>
          <a herf="#">dcdzme</a>
        </li>
        <li>
          <a herf="#">aosmdcde</a>
        </li>
        <li>
          <a herf="#">Hdcdsc</a>
        </li>
      </ul> */}
          <p> &#169;2022 Online | All Rights Reserved</p>
        </footer>
      </div>
    </div>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "../componnent-css/Welcome.css";
import AI from "../Videos/AI.jpg";
import Code from "../Videos/Code.jpg";
import Elearning from "../Videos/E-learning.jpg";
import Ecommerce from "../Videos/E-commerce.jpg";
import Nft from "../Videos/Nft.jpg";
import ilu1 from "../Images/1.png";
import ilu2 from "../Images/2.png";
import ilu3 from "../Images/3.png";
import ilu4 from "../Images/4.png";
import ilu5 from "../Images/5.png";
import ilu6 from "../Images/6.webp";
import ilu7 from "../Images/7.png";
import NFT1 from "../Nfts/NFT1.PNG";
import NFT2 from "../Nfts/NFT2.PNG";
import NFT3 from "../Nfts/NFT3.PNG";
import NFT4 from "../Nfts/NFT4.PNG";
import NFT5 from "../Nfts/NFT5.PNG";
import NFT6 from "../Nfts/NFT6.PNG";
import NFT7 from "../Nfts/NFT7.PNG";
import NFT8 from "../Nfts/NFT8.PNG";
import NFT9 from "../Nfts/NFT9.PNG";
import NFT10 from "../Nfts/NFT10.PNG";
import Clock from "../Nfts/icon-clock.svg";
import Price from "../Nfts/icon-ethereum.svg";
import Avatar from "../Nfts/image-avatar.png";
import Mailer from "./Mailer.js"
import Footer from "./Footer";

axios.defaults.withCredentials = true;
let firstRender = true;

function Welcome() {
  const [user, setUser] = useState();
  const Data = [
    {
      vid: AI,
      Title: "AI~Technology",
      Subtitle: '"The Next Generation Of AI"',
      Par: `The benefits of artificial intelligence as a catalyst for economic growth are distributed unevenly across cities around the world. Consequently, so is its talent. The AI hubs that we know of in Boston, Silicon Valley, Toronto (and so on) don’t exist in the heartland of the Ethiopia or in emerging or frontier markets globally. These AI hubs are all anchored by some of the greatest learning institutions in the world, led by scientific advisors who attract global talent. Without such an AI ecosystem, you will not be able to build the next generation of AI talent.`,
      link1: "/swedhan-stock",
      link2: "/swedhan-stock",
      btn1: "FREE MEMBERSHIP",
      btn2: "BUY STOCK"
    },
    {
      vid: Code,
      Title: "On~Cybersecurity",
      Subtitle: '"Lets Make It Unchallenging"',
      Par: "Security experts, analysts, and vendors use a variety of jargon to describe the makeup of their next-generation endpoint security. Originally, the term referred to solutions that didn’t rely on traditional file scanning methods to detect threats. But the term next-gen now also refers to cybersecurity solutions that use real-time predictive methods like machine learning (ML), artificial intelligence (AI), and behavioral analysis to increase prevention, efficacy, and speed. In some cases, the term extends to automated threat detection and response capabilities.",
      link1: "/swedhan-stock",
      link2: "/swedhan-stock",
      btn1: "FREE MEMBERSHIP",
      btn2: "BUY STOCK"
    },
    {
      vid: Elearning,
      Title: "E~learning~site",
      Subtitle: '"The Greatest For Teacher & Students"',
      Par: "Today, many practices are turning to eLearning for the NextGen Tentref training needs given the many benefits this approach can bring to the organization and your learners. An eLearning-based training program for NextGen Tentref is a cost-effective approach to ensuring that all of your users gain an understanding of the system in order to perform their jobs efficiently. With eLearning, you eliminate the need to hire (expensive) on-site trainers. eLearning also eliminate travel costs, as well as the costs associated with printing and distributing training materials.",
      link1: "/swedhan-stock",
      link2: "/E-learning",
      btn1: "FREE MEMBERSHIP",
      btn2: "Visit Site"
    },
    {
      vid: Ecommerce,
      Title: "E~commerce~site",
      Subtitle: '"Clear Multivendor"',
      Par: "The space will continue to evolve in the direction of providing customers with more selection at better prices and faster shipping. But these are all logistics. There are other elements that play an important role in delighting users. Going forward they’ll continue to be important and companies certainly don’t want to be left behind. Let’s run through some of these important areas.",
      link1: "/swedhan-stock",
      link2: "/E-commerce",
      btn1: "FREE MEMBERSHIP",
      btn2: "Visit Site"
    },
    {
      vid: Nft,
      Title: "The~Swedhan~NFT",
      Subtitle: '"The Swedhan Nfts Collection"',
      Par: "When it first comes out, people are like, Oh it's a website and they try to map it to the offline world. A complex system that works is invariably found to have evolved from a simple system that worked. So in that sense, NFTs are primitive, and people are going to recombine, assemble, and compose those primitives to create incredible complex systems. just visit and come back",
      link1: "/swedhan-stock",
      link2: "https://opensea.io/collection/the-swedhan",
      btn1: "FREE MEMBERSHIP",
      btn2: "VISIT THE NFT"
    }
  ];
  const [image, setImage] = useState(Data[0]);
  console.log(image);

  const refreshToken = async () => {
    const res = await axios
      .get("http://localhost:4000/api/refresh", {
        withCredentials: true
      })
      .catch((err) => console.log(err));

    const data = await res.data;
    return data;
  };
  const sednRequest = async () => {
    const res = await axios
      .get("http://localhost:4000/api/user", {
        withCredentials: true
      })
      .catch((err) => console.log(err));
    const data = await res.data;
    return data;
  };
  useEffect(() => {
    if (firstRender) {
      firstRender = false;
      sednRequest().then((data) => setUser(data.user));
    }
    let interval = setInterval(() => {
      refreshToken().then((data) => setUser(data.user));
    }, 1000 * 29);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <section class="home-welcome">
        <img src={image.vid} alt=""/>
        <div class="content-welcome active">
          <h1 className="h1-welcome" data-text={image.Title}>
            {image.Title}
          </h1>
          <div className="div-welcome">
            <br />
            <span>{image.Subtitle}</span>
          </div>
          <p>{image.Par}</p>
          <div className="btn-container-welcome">
            <a href={image.link1}>
              <span>{image.btn1}</span>
            </a>
            <a href={image.link2}>
              <span>{image.btn2}</span>
            </a>
          </div>
        </div>
        <div class="media-icons-welcome">
          <a href="https://instagram.com/swedhan734?igshid=YmMyMTA2M2Y=">
            <i>
              <ion-icon name="logo-instagram"></ion-icon>
            </i>
          </a>
          <a href="https://twitter.com/swedhan734?t=ebHuCH9KOI_SMCOUGrHq0A&s=09">
            <i>
              <ion-icon name="logo-twitter"></ion-icon>
            </i>
          </a>
          <a href="https://discord.gg/4dPNDUqx">
            <i>
              <ion-icon name="logo-discord"></ion-icon>
            </i>
          </a>
        </div>
        <div class="slider-navigation-welcome">
          <div class="nav-btn-welcome" onClick={() => setImage(Data[0])}>
            {" "}
          </div>
          <div class="nav-btn-welcome" onClick={() => setImage(Data[1])}></div>
          <div class="nav-btn-welcome" onClick={() => setImage(Data[2])}></div>
          <div class="nav-btn-welcome" onClick={() => setImage(Data[3])}></div>
          <div class="nav-btn-welcome" onClick={() => setImage(Data[4])}></div>
        </div>
      </section>
      <h1 id="stock-stock-welcome">Stock To Buy</h1>
      <div className="about-container-welcome">
        <div className="about-box-welcome">
          <span></span>
          <div className="about-content-welcome">
            <h2> Swedhan Tech </h2>
            <p>
              The <strong>Swedhan</strong> tech company is about to start this
              2-3 months which includes technological innovations, cybersecurity
              processes and open software development. 1 stock price is 1000 ETB
              and you can buy a minimum of five stock. And those who buy from
              70-199 stock will get 28% discount and more than 200 stock will
              get 30% discount and founder member certificate.
              <br />
              <br />
              note:
              <h6>*You can buy and sell your stocks at any time *</h6>
              <h6>
                *You can move your cursor to the box or click on the box to read
                it clearly*
              </h6>
            </p>
            <a href="/swedhan-stock"> Buy Stock </a>
          </div>
        </div>
        <div className="about-box-welcome">
          <span></span>
          <div className="about-content-welcome">
            <h2> E-learning </h2>
            <p>A liberating and easy way for teachers and students</p>
            <a disabled href="#">
              {" "}
              So Soon{" "}
            </a>
          </div>
        </div>
        <div className="about-box-welcome">
          <span></span>
          <div className="about-content-welcome">
            <h2> Modern Marketplace </h2>
            <p>
              Modern multivendor marketplace in ethiopia
              <br />
              Soon
            </p>
            <a href="#"> So Soon </a>
          </div>
        </div>
      </div>
      <h1 id="stock-stock-welcome">Why Us?</h1>
      <div className="projects-body-welcome">
        <div className="projects-container-welcome">
          <div className="projects-box-welcome">
            <div className="projects-content-welcome">
              <img src={ilu1} alt="" />
              <p>
                It is accessible and preferred by both teachers and students
              </p>
            </div>
          </div>
          <div className="projects-box-welcome">
            <div className="projects-content-welcome">
              <img src={ilu2} alt="" />
              <p>
                To introduce and exchange knowledge with people who have
                knowledge and wealth
              </p>
            </div>
          </div>
          <div className="projects-box-welcome">
            <div className="projects-content-welcome">
              <img src={ilu3} alt="" />
              <p>
                All questions will be answered by experts with advanced
                knowledge
              </p>
            </div>
          </div>
          <div className="projects-box-welcome">
            <div className="projects-content-welcome">
              <img src={ilu4} alt="" />
              <p>Answer every question asked quickly</p>
            </div>
          </div>
          <div className="projects-box-welcome">
            <div className="projects-content-welcome">
              <img src={ilu5} alt="" />
              <p>
                We will create the website you requested at an amazing price and
                condition, and the transaction will take place after you see the
                finished website.
              </p>
            </div>
          </div>
          <div className="projects-box-welcome">
            <div className="projects-content-welcome">
              <img src={ilu6} alt="" />
              <p>
                Best Multivendor ecommerce website, with amazing variety of
                features and way different for seller and buyers
              </p>
            </div>
          </div>
          <div className="projects-box-welcome">
            <div className="projects-content-welcome">
              <img src={ilu7} alt="" />
              <p>More on various modern technologies</p>
            </div>
          </div>
        </div>
      </div>
      <h1 id="stock-stock-welcome">Swedhan Nft</h1>
      <div className="nft-horizontal-slider">
        <div className="nft-slider-container">
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT1} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #1</h3>
              </a>
              <p className="nft_card_text">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.04134 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>45 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT2} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #2</h3>
              </a>
              <p className="nft_card_text">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.07145 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>78 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT3} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #3</h3>
              </a>
              <p className="nft_card_text">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.03441 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>3days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT4} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #4</h3>
              </a>
              <p className="nft_card_text">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.01249 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>29 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT5} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #5</h3>
              </a>
              <p className="nft_card_text">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.08123 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>54 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT6} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #6</h3>
              </a>
              <p className="nft_card_text" color="red">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.04581 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>39 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT7} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #7</h3>
              </a>
              <p className="nft_card_text">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.04121 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>56 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT8} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #8</h3>
              </a>
              <p className="nft_card_text">On Stock</p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.05641 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>73 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT9} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #9</h3>
              </a>
              <p className="nft_card_text">On Stock </p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.03249 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>32 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
          <div className="nft-item">
            <div className="nft_card_head">
              <div className="nft_card_product_img">
                <img src={NFT10} alt="" />
              </div>
            </div>
            <div className="nft_card_body">
              <a href="https://opensea.io/collection/the-swedhan">
                <h3 className="nft_card_title">swedhan #10</h3>
              </a>
              <p className="nft_card_text">By default is watching </p>
              <div className="nft_wrapper">
                <div className="nft_card_price">
                  <img src={Price} alt="" className="nft_card_icon" />
                  <span>0.07121 ETH</span>
                </div>
                <div className="nft_card_countdown">
                  <img src={Clock} alt="" className="nft_card_icon" />
                  <span>56 days left</span>
                  </div>
              </div>
            </div>
            <div className="nft_card_footer">
              <img src={Avatar} alt="" className="card_author_img" />
              <p className="nft_card_author_name">creation of <a href="https://opensea.io/collection/the-swedhan"> swedhan734</a></p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Mailer/>
      </div>
      <div className="body-faq">
        <div className="container-faq">
      <h1 id="stock-stock-welcome">Frequently Asked Question</h1>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc1" />
            <label for="acc1">
              <h2>01</h2>
              <h3>How Can I Buy Shares?</h3>
            </label>
            <div className="content-faq">
              <p>
              It's Easy To Buy Stock In Swedhan Company, First Log In and Fill The Requirements <a href="#"> Here </a>. 1 Stock Price Is 1000 ETB And You Can Buy A Minimum Of Five Stock. And Those Who Buy From 70-199 Stock Will Get 28% Discount And More Than 200 Stock Will Get 30% Discount And Founder Member Certificate.
              </p>
            </div>
          </div>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc2" />
            <label for="acc2">
              <h2>02</h2>
              <h3>What Is Swedhan Company?</h3>
            </label>
            <div className="content-faq">
              <p>
              Swedhan company has various modern organizations in it, all of them will start on their own time. we mainly focus on modernizing every process. for more <a href=" https://t.me/swedhan734">@swedhan734</a>
              </p>
            </div>
          </div>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc3" />
            <label for="acc3">
              <h2>03</h2>
              <h3>When Will The E-learning will start?</h3>
            </label>
            <div className="content-faq">
              <p>
              We don't have a start date yet, but soon
              </p>
            </div>
          </div>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc4" />
            <label for="acc4">
              <h2>04</h2>
              <h3>When Will the marketplace will start?</h3>
            </label>
            <div className="content-faq">
              <p>
              We don't have a start date yet, but soon
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;

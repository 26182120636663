import React from "react";
import Mailer from "./Mailer";

function Faq() {
  return (
    <div className="questions">
      <div className="body-faq  body-faq-2">
        <div className="container-faq">
      <h1 id="stock-stock-welcome"></h1>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc1" />
            <label for="acc1">
              <h2>01</h2>
              <h3>How Can I Buy Shares?</h3>
            </label>
            <div className="content-faq">
              <p>
              It's Easy To Buy Stock In Swedhan Company, First Log In and Fill The Requirements <a href="#"> Here </a>. 1 Stock Price Is 1000 ETB And You Can Buy A Minimum Of Five Stock. And Those Who Buy From 70-199 Stock Will Get 28% Discount And More Than 200 Stock Will Get 30% Discount And Founder Member Certificate.
              </p>
            </div>
          </div>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc2" />
            <label for="acc2">
              <h2>02</h2>
              <h3>What Is Swedhan Company?</h3>
            </label>
            <div className="content-faq">
              <p>
              Swedhan company has various modern organizations in it, all of them will start on their own time. we mainly focus on modernizing every process. for more <a href=" https://t.me/swedhan734">@swedhan734</a>
              </p>
            </div>
          </div>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc3" />
            <label for="acc3">
              <h2>03</h2>
              <h3>When Will The E-learning will start?</h3>
            </label>
            <div className="content-faq">
              <p>
              We don't have a start date yet, but soon
              </p>
            </div>
          </div>
          <div className="tab-faq">
            <input type="radio" name="acc" id="acc4" />
            <label for="acc4">
              <h2>04</h2>
              <h3>When Will the marketplace will start?</h3>
            </label>
            <div className="content-faq">
              <p>
              We don't have a start date yet, but soon
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Mailer/>
      </div>
    </div>
  );
}

export default Faq;

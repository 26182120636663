import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store";
import "../componnent-css/header.css";
axios.defaults.withCredentials = true;

function Header() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const sendLogoutReq = async () => {
    const res = await axios.post("http://localhost:4000/api/logout", null, {
      withCredentials: true
    });
    if (res.status === 200) {
      return res;
    }
    return new Error("Unable TO Logout. Please try again");
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleLogout = () => {
    sendLogoutReq().then(() => dispatch(authActions.logout()));
  };
  const [value, setValue] = useState();
  return (
    <div>
      <div>
        <header className="navbar-header">
          <span className="nav-logo-header">SwedHan</span>
          <div
            indicatorColor="secondary"
            onChange={(e, val) => setValue(val)}
            value={value}
            textColor="inherit"
            className={`ul-header nav-items-header ${isOpen && "open"}`}
          >
              <li style={{ "--i": "#a955ff", "--j": "#ea51ff" }}>
                <span className="icon-header">
                  <ion-icon name="home-outline"></ion-icon>
                </span>
                <a href="/" onClick={() => setIsOpen(!isOpen)} className="title-header"> Home </a>
              </li>
              <li style={{ "--i": "#56ccf2", "--j": "#2f80ed" }}>
                <span className="icon-header">
                  <ion-icon name="code-working-outline"></ion-icon>
                </span>
                <a href="/projects" onClick={() => setIsOpen(!isOpen)} className="title-header">Projects</a>
              </li>
              <li style={{ "--i": "#ff9966", "--j": "#ff5e62" }}>
                <span className="icon-header">
                  <ion-icon name="cash-outline"></ion-icon>
                </span>
                <a href="/swedhan-stock" onClick={() => setIsOpen(!isOpen)} className="title-header">Stock</a>
              </li>
              {/* <li style={{ "--i": "#80ff72", "--j": "#7ee8fa" }}>
                <span className="icon-header">
                  <ion-icon name="chatbubble-outline"></ion-icon>
                </span>
                <a href="/about-us" onClick={() => setIsOpen(!isOpen)} className="title-header">About us</a>
              </li> */}
              <li style={{ "--i": "#ffa9c6", "--j": "#f434e2" }}>
                <span className="icon-header">
                  <ion-icon name="megaphone-outline"></ion-icon>
                </span>
                <a href="/faq" onClick={() => setIsOpen(!isOpen)} className="title-header">FAQ</a>
              </li>
              {/* {!isLoggedIn && (
                <li style={{ "--i": "#ffa9c6", "--j": "#f434e2" }}>
                  <span className="icon-header">
                  <ion-icon name="log-in-outline"></ion-icon>
                  </span>
                  <span className="title-header a-header ">
                    <Link
                      onClick={() => setIsOpen(!isOpen)}
                      className="a-header"
                      to="/login"
                    >
                      {" "}
                      Login{" "}
                    </Link>
                  </span>
                </li>
              )}
              {!isLoggedIn && (
                <li style={{ "--i": "#ffa9c6", "--j": "#f434e2" }}>
                  <span className="icon-header">
                  <ion-icon name="file-tray-stacked-outline"></ion-icon>
                  </span>
                  <span className="title-header a-header">
                    <Link
                      onClick={() => setIsOpen(!isOpen)}
                      className="a-header"
                      to="/signup"
                    >
                      {" "}
                      Signup
                    </Link>
                  </span>
                </li>
              )}
              {isLoggedIn && (
                <li style={{ "--i": "#ffa9c6", "--j": "#f434e2" }}>
                  <span className="icon-header">
                  <ion-icon name="log-out-outline"></ion-icon>
                  </span>
                  <span className="title-header a-header">
                    <Link className="a-header" onClick={handleLogout} to="/">
                      {" "}
                      Logout{" "}
                    </Link>{" "}
                  </span>
                </li>
              )} */}
          </div>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={`nav-toggle-header ${isOpen && "open"}`}
          >
            <div className="bar-header"></div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;

import React from 'react'

function Projects() {
  return (
    <div className='questions'>
    <div className="about-container-welcome">
      <div className="about-box-welcome">
        <span></span>
        <div className="about-content-welcome">
          <h2> Swedhan Tech </h2>
          <p>
            The <strong>Swedhan</strong> tech company is about to start this
            2-3 months which includes technological innovations, cybersecurity
            processes and open software development. 1 stock price is 1000 ETB
            and you can buy a minimum of five stock. And those who buy from
            70-199 stock will get 28% discount and more than 200 stock will
            get 30% discount and founder member certificate.
            <br />
            <br />
            note:
            <h6>*You can buy and sell your stocks at any time *</h6>
            <h6>
              *You can move your cursor to the box or click on the box to read
              it clearly*
            </h6>
          </p>
          <a href="/swedhan-stock"> Buy Stock </a>
        </div>
      </div>
      <div className="about-box-welcome">
        <span></span>
        <div className="about-content-welcome">
          <h2> E-learning </h2>
          <p>A liberating and easy way for teachers and students</p>
          <a disabled href="#">
            {" "}
            So Soon{" "}
          </a>
        </div>
      </div>
      <div className="about-box-welcome">
        <span></span>
        <div className="about-content-welcome">
          <h2> Modern Marketplace </h2>
          <p>
            Modern multivendor marketplace in ethiopia
            <br />
            Soon
          </p>
          <a href="#"> So Soon </a>
        </div>
      </div>
    </div></div>
  )
}

export default Projects
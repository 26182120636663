import React, { useState } from "react";
import emailjs from "emailjs-com";

function Mailer() {
  const [sent, setSent] = useState("");
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_8twc4ai",
        "template_fycx1p3",
        e.target,
        "BjF-C8VSz05H7GK9b"
      )
      .then((e) =>{
        setSent("Succesfully Sented");
    })
      .catch((err) => {
        setSent("Please Try Again Later")
        console.log(err);
      });
  }
  return (
    <div>
      {" "}
      <h1 id="stock-stock-welcome"> Contact Us</h1>
      <div class="container">
        <span class="big-circle"></span>
        <img src="img/shape.png" class="square" alt="" />
        <div class="form">
          <div class="contact-info">
            <h3 class="title">Let's get in touch</h3>
            <p class="text">
              Anyone Who Wants To Meet Me In Person You can contact me from the communication methods listed below
            </p>

            <div class="info">
              <div class="information">
                <img src="https://pnggrid.com/wp-content/uploads/2021/12/Tel-Phone-Icon-PNG-Transparent-Background.png" class="icon" alt="" />
                <p>+251921216555</p>
              </div>
              <div class="information">
                <img src="https://pnggrid.com/wp-content/uploads/2021/12/Tel-Phone-Icon-PNG-Transparent-Background.png" class="icon" alt="" />
                <p>+251921216555</p>
              </div>
              <div class="information">
                <img src="https://cdn-icons-png.flaticon.com/512/561/561188.png" class="icon" alt="" />
                <p>Swedhan734@gmail.com</p>
              </div>
            </div>

            <div class="social-media">
              <p>Connect with us :</p>
              <div class="social-icons">
                <a href="https://instagram.com/swedhan734?igshid=YmMyMTA2M2Y=">
                <i>
              <ion-icon name="logo-instagram"></ion-icon>
            </i>
                </a>
                <a href="https://discord.gg/4dPNDUqx">
                <i>
              <ion-icon name="logo-discord"></ion-icon>
            </i>                </a>
                <a href="https://twitter.com/swedhan734?t=ebHuCH9KOI_SMCOUGrHq0A&s=09">
                <i>
              <ion-icon name="logo-twitter"></ion-icon>
            </i>                </a>
                <a href="https://www.linkedin.com/in/swed-han-90a1501ab">
                <i>
              <ion-icon name="logo-linkedin"></ion-icon>
            </i>                </a>
              </div>
            </div>
          </div>

          <div class="contact-form">
            <span class="circle one"></span>
            <span class="circle two"></span>

            <form action="index.html" autocomplete="off" onSubmit={sendEmail}>
              <h3 class="title">Contact us</h3>
              <div class="input-container">
                <input
                  type="text"
                  name="name"
                  class="input"
                  placeholder="Username"
                />
              </div>
              <div class="input-container">
                <input
                  type="email"
                  name="email"
                  class="input"
                  placeholder="Email"
                />
              </div>
              <div class="input-container">
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="phone"
                  class="input"
                  placeholder="Phone Number ' Start From 09'"
                />
              </div>
              <div class="input-container textarea">
                <textarea
                  name="message"
                  class="input"
                  placeholder="Message"
                ></textarea>
              </div>
              <p className="done-form-contact-us">{sent}</p>
              <input type="submit" value="Send" class="btn" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mailer;
